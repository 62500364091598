import React from "react";
import { sequence } from "just-animate";
import { addPlugin } from "just-animate";
import { waapiPlugin } from "just-animate/lib/web";

interface DanceProps {
    color: number;
}

// Gets number as prop for selection of color
// Declares color themes for the different elements in the SVG

const ColorThemes = [
    {
        background: "#FDF900",
        ground: "#A4E800",
        man1: "#DC2908",
        man2: "#17A2E5",
        man3: "#80C56A",
        man4: "#EA3A60",
        man5: "#FC7A00",
    },
    {
        background: "#0077BB",
        ground: "#FF5E00",
        man1: "#FC7A00",
        man2: "#DC2908",
        man3: "#17A2E5",
        man4: "#80C56A",
        man5: "#EA3A60",
    },
    {
        background: "#8CCCD5",
        ground: "#D05492",
        man1: "#EA3A60",
        man2: "#FC7A00",
        man3: "#DC2908",
        man4: "#17A2E5",
        man5: "#80C56A",
    },
];

class Dance extends React.Component<DanceProps> {
    constructor(props: DanceProps) {
        super(props);
        addPlugin(waapiPlugin);
    }

    // Starts the animation when it mounts
    componentDidMount() {
        this.animate();
    }

    // Function for animating elements in the svg element. Sets transformationOrigin, duration, delays and animations. Loops infinitely
    animate() {
        const person1Animation = sequence([
            {
                targets: "#person1",
                duration: 0,
                web: {
                    transformOrigin: "22px 60px",
                },
            },
            {
                targets: "#person1",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(-5deg) translateY(-5px)" }],
                },
            },
            {
                targets: "#person1",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(0deg) translateY(0px)" }],
                },
            },
        ]);

        person1Animation.play({
            repeat: Infinity,
        });

        const person2Animation = sequence([
            {
                targets: "#person2",
                duration: 0,
                web: {
                    transformOrigin: "34px 56px",
                },
            },
            {
                targets: "#person2",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(5deg) translateY(-5px)" }],
                },
            },
            {
                targets: "#person2",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(0deg) translateY(0px)" }],
                },
            },
        ]);

        person2Animation.play({
            repeat: Infinity,
        });

        const person3Animation = sequence([
            {
                targets: "#person3",
                duration: 0,
                web: {
                    transformOrigin: "50px 61px",
                },
            },
            {
                targets: "#person3",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(-5deg) translateY(-5px)" }],
                },
            },
            {
                targets: "#person3",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(0deg) translateY(0px)" }],
                },
            },
        ]);

        person3Animation.play({
            repeat: Infinity,
        });

        const person4Animation = sequence([
            {
                targets: "#person4",
                duration: 0,
                web: {
                    transformOrigin: "73px 58px",
                },
            },
            {
                targets: "#person4",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(5deg) translateY(-5px)" }],
                },
            },
            {
                targets: "#person4",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(0deg) translateY(0px)" }],
                },
            },
        ]);

        person4Animation.play({
            repeat: Infinity,
        });

        const person5Animation = sequence([
            {
                targets: "#person5",
                duration: 0,
                web: {
                    transformOrigin: "89px 61px",
                },
            },
            {
                targets: "#person5",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(-5deg) translateY(-5px)" }],
                },
            },
            {
                targets: "#person5",
                duration: 500,
                web: {
                    transform: [{ value: "rotate(0deg) translateY(0px)" }],
                },
            },
        ]);

        person5Animation.play({
            repeat: Infinity,
        });

        const strokesAnimation = sequence([
            {
                targets: "#strokes",
                duration: 0,
                web: {
                    transformOrigin: "50px 75px",
                },
            },
            {
                targets: "#strokes",
                duration: 500,
                web: {
                    transform: [{ value: "scale(1.1)" }],
                },
            },
            {
                targets: "#strokes",
                duration: 500,
                web: {
                    transform: [{ value: "scale(1)" }],
                },
            },
        ]);

        strokesAnimation.play({
            repeat: Infinity,
        });
    }

    render() {
        return (
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                version="1.1"
                style={{
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                }}
            >
                <g id="Layer1">
                    <rect
                        x="0"
                        y="0"
                        width="100"
                        height="100"
                        style={{
                            fill: ColorThemes[this.props.color].background,
                        }}
                    />
                    <g id="ground">
                        <path
                            d="M110.964,79l-113.964,2.852l0.5,19.994l113.965,-2.852l-0.501,-19.994Z"
                            style={{
                                fill: ColorThemes[this.props.color].ground,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path d="M3.573,84.688c0.828,-0.02 1.511,0.411 1.525,0.962c0.013,0.552 -0.647,1.017 -1.475,1.038c-0.828,0.02 -1.511,-0.411 -1.525,-0.963c-0.013,-0.551 0.647,-1.016 1.475,-1.037Z" />
                        <path d="M0.958,82.023c0.827,-0.021 1.51,0.411 1.524,0.962c0.014,0.552 -0.647,1.017 -1.474,1.038c-0.828,0.02 -1.511,-0.411 -1.525,-0.963c-0.014,-0.551 0.647,-1.016 1.475,-1.037Z" />
                        <path d="M8.228,85.35c0.552,-0.014 1.012,0.453 1.027,1.042c0.015,0.589 -0.421,1.079 -0.973,1.092c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.972,-1.092Z" />
                        <path d="M12.52,85.84c0.646,-0.016 1.183,0.449 1.197,1.038c0.015,0.588 -0.498,1.08 -1.144,1.096c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.096Z" />
                        <path d="M16.896,86.341c0.553,-0.014 1.012,0.394 1.025,0.91c0.013,0.517 -0.425,0.947 -0.978,0.961c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M25.27,86.19c-0.828,0.021 -1.511,-0.41 -1.524,-0.962c-0.014,-0.552 0.646,-1.017 1.474,-1.037c0.828,-0.021 1.511,0.41 1.525,0.962c0.013,0.552 -0.647,1.016 -1.475,1.037Z" />
                        <path d="M20.615,85.528c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.589 -0.422,1.078 -0.973,1.092Z" />
                        <path d="M16.324,85.039c-0.647,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096c0.646,-0.017 1.183,0.448 1.198,1.037c0.015,0.589 -0.498,1.08 -1.144,1.097Z" />
                        <path d="M14.706,82.79c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.588 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M11.947,84.537c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.012,-0.516 0.425,-0.947 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.946 -0.977,0.96Z" />
                        <path d="M2.414,91.237c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096Z" />
                        <path d="M6.791,91.738c0.553,-0.013 1.012,0.394 1.025,0.911c0.012,0.516 -0.425,0.946 -0.978,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M15.165,91.587c-0.828,0.021 -1.511,-0.41 -1.525,-0.962c-0.013,-0.552 0.647,-1.016 1.475,-1.037c0.828,-0.021 1.511,0.41 1.525,0.962c0.013,0.552 -0.647,1.017 -1.475,1.037Z" />
                        <path d="M10.51,90.925c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.552,-0.013 1.012,0.453 1.027,1.042c0.014,0.589 -0.422,1.079 -0.973,1.092Z" />
                        <path d="M6.918,83.878c-0.552,0.013 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.079 0.973,-1.092c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.589 -0.422,1.078 -0.973,1.092Z" />
                        <path d="M6.218,90.436c-0.646,0.016 -1.182,-0.449 -1.197,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096c0.646,-0.016 1.183,0.449 1.198,1.038c0.015,0.588 -0.498,1.08 -1.145,1.096Z" />
                        <path d="M1.842,89.935c-0.553,0.013 -1.012,-0.395 -1.025,-0.911c-0.013,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.961Z" />
                        <path d="M20.806,87.043c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.552 -0.647,1.016 -1.475,1.037c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.017 1.474,-1.037Z" />
                        <path d="M25.462,87.705c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M29.753,88.194c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096c-0.646,0.017 -1.183,-0.448 -1.197,-1.037c-0.015,-0.589 0.498,-1.081 1.144,-1.097Z" />
                        <path d="M34.13,88.696c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.946 -0.977,0.96c-0.553,0.014 -1.012,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.96Z" />
                        <path d="M42.504,88.545c-0.828,0.02 -1.511,-0.411 -1.525,-0.963c-0.014,-0.551 0.647,-1.016 1.475,-1.037c0.827,-0.02 1.51,0.411 1.524,0.962c0.014,0.552 -0.647,1.017 -1.474,1.038Z" />
                        <path d="M37.848,87.883c-0.552,0.013 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.079 0.973,-1.092c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M33.557,87.393c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.096c0.646,-0.016 1.183,0.449 1.197,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M29.18,86.892c-0.552,0.014 -1.011,-0.394 -1.024,-0.911c-0.013,-0.516 0.425,-0.946 0.977,-0.96c0.553,-0.014 1.012,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.977,0.961Z" />
                        <path d="M32.398,93.942c-0.827,0.021 -1.51,-0.411 -1.524,-0.962c-0.014,-0.552 0.647,-1.017 1.474,-1.037c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.551 -0.647,1.016 -1.475,1.037Z" />
                        <path d="M27.743,93.28c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M23.452,92.79c-0.646,0.017 -1.183,-0.448 -1.198,-1.037c-0.015,-0.589 0.498,-1.081 1.145,-1.097c0.646,-0.016 1.182,0.449 1.197,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M19.075,92.289c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961c0.553,-0.013 1.011,0.394 1.024,0.911c0.013,0.516 -0.425,0.946 -0.977,0.96Z" />
                        <path d="M19.541,95.146c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.552,-0.013 1.012,0.453 1.027,1.042c0.014,0.589 -0.422,1.079 -0.973,1.092Z" />
                        <path d="M15.249,94.657c-0.646,0.016 -1.182,-0.449 -1.197,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096c0.646,-0.016 1.183,0.449 1.198,1.037c0.015,0.589 -0.498,1.081 -1.145,1.097Z" />
                        <path d="M10.873,94.155c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.013,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.96Z" />
                        <path d="M32.04,96.497c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M27.749,96.007c-0.646,0.017 -1.183,-0.448 -1.197,-1.037c-0.015,-0.589 0.498,-1.081 1.144,-1.097c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096Z" />
                        <path d="M23.372,95.506c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961c0.553,-0.013 1.012,0.394 1.025,0.911c0.013,0.516 -0.425,0.946 -0.978,0.96Z" />
                        <path d="M12.561,97.131c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.079 0.973,-1.092c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M9.688,99.58c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.588 0.421,-1.078 0.973,-1.092c0.551,-0.013 1.011,0.454 1.026,1.042c0.015,0.589 -0.421,1.079 -0.973,1.092Z" />
                        <path d="M8.27,96.641c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.588 0.498,-1.08 1.145,-1.096c0.646,-0.016 1.183,0.449 1.197,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M5.397,99.091c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.096c0.646,-0.016 1.183,0.449 1.197,1.038c0.015,0.588 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M3.893,96.14c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961c0.553,-0.014 1.012,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.977,0.961Z" />
                        <path d="M1.827,97.73c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.012,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.96Z" />
                        <path d="M0.698,100.296c-0.552,0.014 -1.011,-0.394 -1.024,-0.911c-0.013,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.978,0.961Z" />
                        <path d="M25.338,98.535c-0.552,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M23.11,101.13c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.078 0.973,-1.092c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.589 -0.422,1.078 -0.973,1.092Z" />
                        <path d="M21.047,98.046c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.097c0.646,-0.016 1.183,0.449 1.197,1.038c0.015,0.589 -0.498,1.08 -1.144,1.097Z" />
                        <path d="M18.174,100.495c-0.646,0.016 -1.183,-0.449 -1.198,-1.037c-0.014,-0.589 0.498,-1.081 1.145,-1.097c0.646,-0.016 1.183,0.449 1.197,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M16.67,97.544c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.96c0.553,-0.014 1.012,0.394 1.025,0.91c0.012,0.516 -0.425,0.946 -0.978,0.96Z" />
                        <path d="M13.797,99.994c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961c0.553,-0.014 1.012,0.394 1.025,0.911c0.013,0.516 -0.425,0.946 -0.978,0.96Z" />
                        <path d="M45.898,98.285c-0.551,0.013 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.079 0.973,-1.092c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M38.138,99.75c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.015,-0.589 0.421,-1.079 0.973,-1.092c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.972,1.092Z" />
                        <path d="M51.469,101.354c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M41.607,97.795c-0.646,0.016 -1.183,-0.449 -1.197,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096Z" />
                        <path d="M33.846,99.26c-0.646,0.016 -1.183,-0.449 -1.197,-1.038c-0.015,-0.588 0.498,-1.08 1.144,-1.096c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096Z" />
                        <path d="M47.178,100.864c-0.646,0.017 -1.183,-0.448 -1.197,-1.037c-0.015,-0.589 0.498,-1.081 1.144,-1.097c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096Z" />
                        <path d="M37.23,97.294c-0.552,0.014 -1.011,-0.394 -1.024,-0.911c-0.013,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.978,0.961Z" />
                        <path d="M29.47,98.759c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.013,-0.517 0.425,-0.947 0.978,-0.961c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.977,0.961Z" />
                        <path d="M28.237,101.128c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.978,0.96Z" />
                        <path d="M42.801,100.363c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.978,-0.961c0.552,-0.013 1.011,0.394 1.024,0.911c0.013,0.516 -0.425,0.946 -0.978,0.96Z" />
                        <path d="M41.826,85.328c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.551,-0.013 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.079 -0.973,1.092Z" />
                        <path d="M41.732,82.629c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M37.535,84.839c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.096c0.646,-0.016 1.183,0.449 1.197,1.037c0.015,0.589 -0.498,1.081 -1.144,1.097Z" />
                        <path d="M35.709,82.688c-0.647,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.588 0.498,-1.08 1.144,-1.096c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M33.158,84.337c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.946 0.977,-0.96c0.553,-0.014 1.012,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.96Z" />
                        <path d="M29.483,84.02c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.079 0.973,-1.092c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.589 -0.422,1.078 -0.973,1.092Z" />
                        <path d="M25.192,83.53c-0.647,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M20.815,83.029c-0.553,0.014 -1.012,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.977,0.961Z" />
                        <path d="M37.643,89.333c0.827,-0.021 1.51,0.411 1.524,0.962c0.014,0.552 -0.647,1.017 -1.474,1.037c-0.828,0.021 -1.511,-0.41 -1.525,-0.962c-0.014,-0.551 0.647,-1.016 1.475,-1.037Z" />
                        <path d="M42.298,89.995c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M46.589,90.485c0.646,-0.017 1.183,0.448 1.198,1.037c0.015,0.589 -0.498,1.081 -1.145,1.097c-0.646,0.016 -1.182,-0.449 -1.197,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096Z" />
                        <path d="M50.966,90.986c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.961c-0.553,0.013 -1.011,-0.394 -1.024,-0.911c-0.013,-0.516 0.425,-0.946 0.977,-0.96Z" />
                        <path d="M59.34,90.835c-0.828,0.021 -1.511,-0.41 -1.525,-0.962c-0.014,-0.552 0.647,-1.017 1.475,-1.037c0.827,-0.021 1.51,0.41 1.524,0.962c0.014,0.552 -0.647,1.016 -1.474,1.037Z" />
                        <path d="M54.684,90.173c-0.552,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.552,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M50.393,89.683c-0.646,0.017 -1.183,-0.448 -1.198,-1.037c-0.014,-0.589 0.498,-1.08 1.145,-1.097c0.646,-0.016 1.183,0.449 1.197,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M46.016,89.182c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961c0.553,-0.013 1.012,0.395 1.025,0.911c0.013,0.516 -0.425,0.946 -0.978,0.96Z" />
                        <path d="M49.235,96.232c-0.828,0.021 -1.511,-0.41 -1.525,-0.962c-0.014,-0.552 0.647,-1.016 1.475,-1.037c0.827,-0.021 1.51,0.41 1.524,0.962c0.014,0.552 -0.647,1.017 -1.474,1.037Z" />
                        <path d="M44.579,95.57c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M40.288,95.081c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.096c0.646,-0.017 1.183,0.448 1.197,1.037c0.015,0.589 -0.498,1.081 -1.144,1.097Z" />
                        <path d="M35.911,94.579c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.946 0.977,-0.96c0.553,-0.014 1.012,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.96Z" />
                        <path d="M47.194,80.898c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.552 -0.647,1.016 -1.475,1.037c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.016 1.474,-1.037Z" />
                        <path d="M51.85,81.56c0.552,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.552,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M57.718,79.742c0.552,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.552,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M56.141,82.049c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096c-0.646,0.017 -1.183,-0.448 -1.197,-1.037c-0.015,-0.589 0.498,-1.08 1.144,-1.097Z" />
                        <path d="M60.518,82.551c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.946 -0.977,0.96c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.013,-0.516 0.425,-0.946 0.978,-0.96Z" />
                        <path d="M68.891,82.4c-0.827,0.02 -1.51,-0.411 -1.524,-0.962c-0.014,-0.552 0.647,-1.017 1.474,-1.038c0.828,-0.02 1.511,0.411 1.525,0.962c0.014,0.552 -0.647,1.017 -1.475,1.038Z" />
                        <path d="M58.786,87.797c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.017 1.474,-1.037c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.551 -0.647,1.016 -1.475,1.037Z" />
                        <path d="M54.131,87.135c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.078 0.973,-1.092c0.552,-0.014 1.012,0.453 1.027,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M49.84,86.645c-0.647,0.017 -1.183,-0.448 -1.198,-1.037c-0.015,-0.589 0.498,-1.081 1.144,-1.097c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M45.463,86.144c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961c0.552,-0.013 1.011,0.394 1.024,0.911c0.013,0.516 -0.425,0.946 -0.977,0.96Z" />
                        <path d="M64.986,83.135c0.828,-0.021 1.511,0.411 1.525,0.962c0.014,0.552 -0.647,1.017 -1.475,1.037c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.551 0.647,-1.016 1.474,-1.037Z" />
                        <path d="M63.107,80.251c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.552 -0.647,1.016 -1.475,1.037c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.016 1.474,-1.037Z" />
                        <path d="M94.919,93.581c0.827,-0.021 1.511,0.41 1.524,0.962c0.014,0.552 -0.647,1.017 -1.474,1.037c-0.828,0.021 -1.511,-0.41 -1.525,-0.962c-0.014,-0.551 0.647,-1.016 1.475,-1.037Z" />
                        <path d="M99.986,93.641c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.552 -0.647,1.017 -1.475,1.037c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.016 1.474,-1.037Z" />
                        <path d="M98.635,90.881c0.828,-0.021 1.511,0.41 1.524,0.962c0.014,0.552 -0.646,1.016 -1.474,1.037c-0.828,0.021 -1.511,-0.41 -1.525,-0.962c-0.013,-0.552 0.647,-1.017 1.475,-1.037Z" />
                        <path d="M69.642,83.797c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M73.933,84.287c0.646,-0.017 1.183,0.448 1.197,1.037c0.015,0.589 -0.498,1.081 -1.144,1.097c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.096Z" />
                        <path d="M78.31,84.788c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.961c-0.553,0.013 -1.012,-0.394 -1.025,-0.911c-0.013,-0.516 0.425,-0.946 0.978,-0.96Z" />
                        <path d="M86.683,84.637c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.017 1.474,-1.037c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.552 -0.647,1.016 -1.475,1.037Z" />
                        <path d="M76.578,90.034c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.016 1.474,-1.037c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.552 -0.647,1.017 -1.475,1.037Z" />
                        <path d="M71.923,89.372c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.078 0.973,-1.092c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M67.632,88.883c-0.647,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096c0.647,-0.017 1.183,0.448 1.198,1.037c0.015,0.589 -0.498,1.081 -1.144,1.097Z" />
                        <path d="M60.285,92.297c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.588 -0.422,1.078 -0.973,1.092c-0.552,0.013 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.079 0.973,-1.092Z" />
                        <path d="M64.576,92.786c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096c-0.647,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.588 0.498,-1.08 1.144,-1.096Z" />
                        <path d="M68.953,93.287c0.553,-0.013 1.012,0.395 1.024,0.911c0.013,0.516 -0.425,0.946 -0.977,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M66.978,96c0.552,-0.013 1.011,0.394 1.024,0.911c0.013,0.516 -0.425,0.946 -0.978,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.978,-0.961Z" />
                        <path d="M86.846,95.304c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.961c-0.553,0.013 -1.012,-0.395 -1.025,-0.911c-0.013,-0.516 0.425,-0.946 0.978,-0.96Z" />
                        <path d="M84.059,97.473c0.553,-0.013 1.011,0.394 1.024,0.911c0.013,0.516 -0.425,0.946 -0.977,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M77.327,93.136c-0.828,0.021 -1.511,-0.41 -1.525,-0.962c-0.013,-0.551 0.647,-1.016 1.475,-1.037c0.828,-0.021 1.511,0.411 1.524,0.962c0.014,0.552 -0.646,1.017 -1.474,1.037Z" />
                        <path d="M72.671,92.474c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.588 0.421,-1.078 0.973,-1.092c0.552,-0.013 1.012,0.454 1.026,1.042c0.015,0.589 -0.421,1.079 -0.973,1.092Z" />
                        <path d="M68.38,91.985c-0.646,0.016 -1.183,-0.449 -1.197,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.588 -0.498,1.08 -1.145,1.096Z" />
                        <path d="M64.003,91.484c-0.552,0.013 -1.011,-0.394 -1.024,-0.911c-0.013,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.978,0.961Z" />
                        <path d="M55.794,93.411c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.978,0.96Z" />
                        <path d="M53.836,96.414c-0.552,0.013 -1.011,-0.395 -1.024,-0.911c-0.013,-0.516 0.425,-0.946 0.978,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.978,0.961Z" />
                        <path d="M50.075,99.019c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.977,0.961Z" />
                        <path d="M62.566,97.872c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.079 0.973,-1.092c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M64.351,100.637c-0.551,0.013 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.079 0.973,-1.092c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M58.805,100.477c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.078 0.973,-1.092c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.589 -0.422,1.078 -0.973,1.092Z" />
                        <path d="M58.275,97.382c-0.646,0.016 -1.183,-0.449 -1.197,-1.038c-0.015,-0.588 0.498,-1.08 1.144,-1.096c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096Z" />
                        <path d="M54.514,99.987c-0.647,0.017 -1.183,-0.448 -1.198,-1.037c-0.015,-0.589 0.498,-1.08 1.144,-1.097c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M85.619,91.184c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.096c0.646,-0.016 1.183,0.449 1.197,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M63.255,88.381c-0.553,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.946 0.977,-0.96c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.96Z" />
                        <path d="M82.612,85.606c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.552 -0.647,1.017 -1.475,1.037c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.016 1.474,-1.037Z" />
                        <path d="M87.268,86.268c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M91.559,86.757c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096c-0.646,0.017 -1.183,-0.448 -1.197,-1.037c-0.015,-0.589 0.498,-1.08 1.144,-1.097Z" />
                        <path d="M95.936,87.259c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.96c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.012,-0.516 0.426,-0.946 0.978,-0.96Z" />
                        <path d="M99.271,87.329c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.96c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.013,-0.516 0.425,-0.946 0.978,-0.96Z" />
                        <path d="M90.218,83.518c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M94.509,84.008c0.646,-0.016 1.183,0.449 1.197,1.037c0.015,0.589 -0.498,1.081 -1.144,1.097c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.014,-0.589 0.498,-1.08 1.145,-1.096Z" />
                        <path d="M98.886,84.509c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.978,0.961c-0.552,0.014 -1.011,-0.394 -1.024,-0.911c-0.013,-0.516 0.425,-0.946 0.978,-0.96Z" />
                        <path d="M81.84,91.837c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.589 -0.421,1.078 -0.973,1.092c-0.552,0.013 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.079 0.973,-1.092Z" />
                        <path d="M86.131,92.326c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.588 0.498,-1.08 1.144,-1.096Z" />
                        <path d="M90.508,92.828c0.553,-0.014 1.012,0.394 1.025,0.91c0.012,0.516 -0.425,0.946 -0.978,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.96Z" />
                        <path d="M73.488,93.421c0.552,-0.013 1.012,0.453 1.027,1.042c0.015,0.589 -0.421,1.079 -0.973,1.092c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.014,-0.588 0.422,-1.078 0.973,-1.092Z" />
                        <path d="M69.385,98.593c0.552,-0.014 1.012,0.453 1.027,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.078 0.973,-1.092Z" />
                        <path d="M71.513,96.134c0.552,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.079 -0.973,1.092c-0.552,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M91.381,95.438c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M88.594,97.607c0.552,-0.014 1.012,0.453 1.027,1.042c0.014,0.589 -0.422,1.079 -0.973,1.092c-0.552,0.014 -1.012,-0.453 -1.027,-1.042c-0.014,-0.589 0.422,-1.078 0.973,-1.092Z" />
                        <path d="M77.779,93.911c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096Z" />
                        <path d="M75.804,96.624c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.588 -0.498,1.08 -1.145,1.096c-0.646,0.016 -1.183,-0.449 -1.197,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096Z" />
                        <path d="M95.672,95.927c0.646,-0.016 1.183,0.449 1.198,1.038c0.014,0.589 -0.498,1.08 -1.145,1.096c-0.646,0.017 -1.183,-0.448 -1.197,-1.037c-0.015,-0.589 0.498,-1.08 1.144,-1.097Z" />
                        <path d="M92.885,98.097c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.588 -0.498,1.08 -1.144,1.096c-0.647,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096Z" />
                        <path d="M82.156,94.412c0.553,-0.014 1.012,0.394 1.025,0.911c0.012,0.516 -0.425,0.946 -0.978,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M80.181,97.125c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.977,0.961c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.013,-0.517 0.425,-0.947 0.978,-0.961Z" />
                        <path d="M100.049,96.429c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.96c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.012,-0.516 0.426,-0.946 0.978,-0.96Z" />
                        <path d="M97.262,98.598c0.553,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.977,0.961c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M73.842,80.941c0.552,-0.014 1.012,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092c-0.551,0.014 -1.011,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092Z" />
                        <path d="M78.133,81.431c0.646,-0.017 1.183,0.448 1.198,1.037c0.014,0.589 -0.498,1.081 -1.145,1.097c-0.646,0.016 -1.183,-0.449 -1.197,-1.038c-0.015,-0.589 0.498,-1.08 1.144,-1.096Z" />
                        <path d="M82.51,81.932c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.516 -0.425,0.947 -0.977,0.961c-0.553,0.013 -1.012,-0.394 -1.024,-0.911c-0.013,-0.516 0.425,-0.946 0.977,-0.96Z" />
                        <path d="M81.38,79.251c0.553,-0.014 1.012,0.394 1.024,0.911c0.013,0.516 -0.425,0.946 -0.977,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.517 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M89.449,80.043c0.552,-0.014 1.012,0.453 1.027,1.042c0.015,0.588 -0.421,1.078 -0.973,1.092c-0.552,0.013 -1.012,-0.454 -1.027,-1.042c-0.014,-0.589 0.422,-1.079 0.973,-1.092Z" />
                        <path d="M93.741,80.532c0.646,-0.016 1.182,0.449 1.197,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096c-0.646,0.016 -1.183,-0.449 -1.198,-1.038c-0.015,-0.588 0.498,-1.08 1.145,-1.096Z" />
                        <path d="M98.117,81.033c0.553,-0.013 1.012,0.395 1.025,0.911c0.013,0.516 -0.425,0.946 -0.978,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M96.325,79.091c0.552,-0.014 1.011,0.394 1.024,0.91c0.013,0.517 -0.425,0.947 -0.977,0.961c-0.553,0.014 -1.012,-0.394 -1.025,-0.91c-0.012,-0.517 0.425,-0.947 0.978,-0.961Z" />
                        <path d="M86.245,79.119c0.552,-0.013 1.011,0.394 1.024,0.911c0.013,0.516 -0.425,0.946 -0.977,0.96c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961Z" />
                        <path d="M94.204,92.505c-0.827,0.021 -1.51,-0.41 -1.524,-0.962c-0.014,-0.552 0.647,-1.017 1.474,-1.037c0.828,-0.021 1.511,0.41 1.525,0.962c0.014,0.551 -0.647,1.016 -1.475,1.037Z" />
                        <path d="M89.549,91.843c-0.552,0.014 -1.012,-0.453 -1.026,-1.042c-0.015,-0.589 0.421,-1.078 0.973,-1.092c0.551,-0.014 1.011,0.453 1.026,1.042c0.015,0.589 -0.421,1.078 -0.973,1.092Z" />
                        <path d="M85.258,91.353c-0.646,0.017 -1.183,-0.448 -1.198,-1.037c-0.015,-0.589 0.498,-1.08 1.144,-1.097c0.647,-0.016 1.183,0.449 1.198,1.038c0.015,0.589 -0.498,1.08 -1.144,1.096Z" />
                        <path d="M80.881,90.852c-0.552,0.014 -1.011,-0.394 -1.024,-0.91c-0.013,-0.516 0.425,-0.947 0.977,-0.961c0.553,-0.013 1.011,0.395 1.024,0.911c0.013,0.516 -0.425,0.946 -0.977,0.96Z" />
                    </g>
                    <g id="strokes">
                        <g>
                            <path
                                d="M32.809,33.181c0,0 5.12,0.151 6.743,1.843"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M34.73,36.006c0,0 2.273,-0.064 3.208,1.002"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M37.969,32.139c-0,-0 2.051,0.052 2.882,0.912"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M9.505,35.177c0,-0 2.411,-3.174 3.618,-3.54"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M16.196,32.146c-0,-0 -5.098,3.568 -5.771,5.986"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M12.555,38.019c0,0 2.795,-3.955 4.697,-4.121"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M94.367,43.677c0.946,0.253 2.297,1.592 2.297,2.461"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M92.128,44.178c1.129,0.501 3.372,3.291 3.386,4.379"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M91.539,46.602c1.148,0.453 2.337,1.932 2.35,2.702"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M87.6,39.204c0.764,0.73 1.44,2.266 1.315,3.226"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M84.529,39.472c0.646,0.398 2.936,3.254 2.306,5.324"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M83.466,41.342c0.632,0.459 1.256,1.729 1.243,2.456"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M94.526,76.045l0.802,-1.497"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M92.343,76.298l1.498,-2.313"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M90.972,74.982l1.1,-1.907"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M79.112,73.438c-0,-0 1.611,-1.157 1.865,-1.452"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M76.055,73.362c-0,-0 4.419,-2.733 4.709,-3.539"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M76.616,70.996c0,-0 1.864,-1.042 2.236,-1.779"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M57.448,78.969l1.027,-0.206"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M55.808,77.934l1.231,-0.664"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M57.563,73.809c-0,-0 2.894,-0.53 3.311,-1.79"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M57.506,71.615c0.725,0.055 2.307,-0.6 2.447,-1.096"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M62.058,65.356c1.289,0.044 2.576,-0.873 2.944,-1.452"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M61.882,63.533c0,-0 1.975,-0.658 2.452,-1.209"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M66.481,33.79c0,-0 3.188,1.571 3.598,2.458"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M68.9,38.448c0,0 -4.122,-4.102 -7.614,-4.123"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M62.492,37.379c0,0 4.347,1.686 4.703,2.969"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M52.696,41.217c0,0 2.97,0.267 3.647,1.407"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M55.233,44.948c-1.262,-1.26 -3.716,-2.529 -5.73,-2.047"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M50.694,45.493c-0,0 2.521,0.235 3.142,0.927"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M41.631,56.002c0,-0 0.211,-1.145 0.693,-1.479"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M42.974,57.622c0,0 0.114,-1.791 0.555,-2.044"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M2.911,78.674c-0,-0 1.451,0.064 2.1,-0.114"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M3.995,74.967l2.058,-0.157l-2.058,0.157Z"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M3.456,76.598c0,0 2.552,0.144 3.105,-0.103"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M15.039,78.645l1.242,-0.868l-1.242,0.868Z"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M15.846,79.811l1.435,-0.694"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M22.009,79.173l0.987,-0.934l-0.987,0.934Z"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M23.279,80.064l1.433,-1.645l-1.433,1.645Z"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M25.331,80.058l0.622,-1.058"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M32.447,78.132l-0.027,1.078l0.027,-1.078Z"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M34.378,77.582l-0.037,1.843l0.037,-1.843Z"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M36.445,77.722l-0.054,1.341l0.054,-1.341Z"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M25.348,70.004c0,0 1.502,-0.813 1.703,-1.129"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M23.853,66.337c-0,0 1.362,-0.552 2.01,-1.136"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M23.69,68.191c0,0 2.991,-0.544 3.647,-1.329"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                        <g>
                            <path
                                d="M21.48,46.537c0,0 0.227,1.364 0.118,1.789"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M19.295,45.119c-0,-0 0.578,2.141 0.322,3.134"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                            <path
                                d="M17.605,45.68c-0,0 0.195,0.89 0.176,1.649"
                                style={{
                                    fill: "none",
                                    stroke: "#000",
                                    strokeWidth: "1.39px",
                                }}
                            />
                        </g>
                    </g>
                    <g id="person1">
                        <path
                            d="M6.545,81.084l3.326,-5.689c0,-0 -4.259,-2.64 -4.247,-7.519c0.012,-4.879 0.728,-25.202 -0.146,-26.192c-1.586,0.095 -3.741,-2.597 -0.472,-3.579c2.079,-0.596 2.266,1.221 2.769,2.956c0.359,1.903 0.944,7.439 1.319,7.94c0.375,0.501 6.617,1.962 6.617,1.962c0,0 8.59,0.021 9.758,1.194c0.313,1.187 -0.867,6.423 -0.834,6.561c2.429,0.355 3.417,3.68 -3.244,2.008c-0.063,-0.875 1.031,-5.298 0.797,-6.376c-0.656,0.034 -5.021,0.03 -5.021,0.03l-0.481,9.542l4.087,0.892c-0,-0 0.65,8.658 0.638,8.703c2.761,-0.773 7.6,2.674 -3.136,3.992c-0.218,-0.195 -1.229,-9.055 -1.229,-9.055c-0,0 -4.807,1.416 -6.519,1.308c0.662,0.951 3.767,4.597 3.767,4.597c0,-0 -3.498,4.798 -3.466,5.152c2.605,1.263 3.362,5.715 -4.283,1.573Z"
                            style={{
                                fill: ColorThemes[this.props.color].man1,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path
                            d="M11.272,52.329c-2.31,-0.606 -3.605,-3.835 -0.44,-5.04c3.165,-1.204 5.809,1.461 4.68,4.397"
                            style={{
                                fill: ColorThemes[this.props.color].man1,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                    </g>
                    <g id="person2">
                        <path
                            d="M33.747,75.764c0,0 -1.05,-13.136 -0.485,-13.361c0.566,-0.226 2.269,-0.541 2.523,0.09c0.254,0.63 0.237,12.082 0.717,12.877c5.205,3.966 9.695,1.956 3.484,-1.211c0.046,-0.259 1.25,-20.144 -1.572,-25.648c0.647,-0.993 5.92,-10.708 7.253,-11.46c2.859,-0.813 2.728,-5.144 -2.095,-2.238c-3.302,3.223 -6.786,9.9 -7.217,10.433c-0.431,0.532 -7.517,1.23 -7.517,1.23c-0,-0 -9.907,-8.99 -11.671,-8.658c-1.764,0.333 -2.225,2.869 0.798,3.156c2.368,1.244 11.083,9.002 11.487,9.828c-0.062,0.932 0.295,20.797 1.061,23.335c-4.439,2.367 -3.91,4.652 3.234,1.627Z"
                            style={{
                                fill: ColorThemes[this.props.color].man2,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path
                            d="M31.961,49.558c-3.197,-0.786 -4.141,-4.55 0.209,-5.355c4.35,-0.806 5.665,3.618 3.843,5.643"
                            style={{
                                fill: ColorThemes[this.props.color].man2,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                    </g>
                    <g id="person3">
                        <path
                            d="M48.128,76.854c0,-0 -5.021,-5.349 -4.922,-6.41c0.099,-1.062 4.352,-9.071 3.982,-9.529c-0.37,-0.458 -3.958,-3.934 -1.049,-5.51c1.419,-0.684 6.502,-2.443 6.502,-2.443l6.089,-1.828c0,0 3.406,6.451 3.574,6.374c2.136,-0.466 2.651,1.604 -0.335,3.078c-1.221,-0.719 -5.565,-5.284 -5.565,-5.284c-0,0 -0.301,9.174 -5.688,14.609c0.594,0.894 5.441,4.844 4.965,5.492c-1.452,1.763 -4.697,4.976 -5.629,4.02c-2.159,2.674 -7.463,2.765 -1.924,-2.569Z"
                            style={{
                                fill: ColorThemes[this.props.color].man3,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path
                            d="M48.932,55.192c-2.566,0.215 -6.306,-1.656 -1.847,-4.717c3.849,-1.898 5.516,2.982 5.53,3.235"
                            style={{
                                fill: ColorThemes[this.props.color].man3,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path
                            d="M47.466,61.083c-0,-0 8.156,5.215 8.231,5.698c-0.517,2.258 2.786,3.412 3.015,-1.098c-1.914,-0.999 -9.239,-6.118 -9.743,-7.399"
                            style={{
                                fill: ColorThemes[this.props.color].man3,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path
                            d="M48.127,66.017l-1.533,5.656l5.373,3.99c0,0 -2.561,1.549 -1.966,3.348"
                            style={{
                                fill: "none",
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                    </g>
                    <g id="person4">
                        <path
                            d="M66.014,77.177l-2.425,-3.54l6.232,-14.931c0,0 -7.166,-9.228 -7.307,-11.262c-1.291,0.131 -2.958,-0.079 -3.315,0.131c-3.118,2.019 -4.549,-2.334 3.268,-3.444c0.542,0.55 6.604,6.632 6.604,6.632l4.894,-1.796l4.063,-4.114c0,-0 -6.621,-3.152 -6.66,-3.426c0.496,-3.764 3.608,-4.159 3.436,-1.009c0.64,0.563 7.288,4.564 7.528,4.983c0.241,0.42 -5.431,5.658 -5.431,5.658c-0,0 3.458,9.949 -5.301,20.802c0.453,0.731 3.263,5.578 3.263,5.578c-0,0 -5.216,4.293 -6.287,2.098c-4.904,1.799 -9.356,0.482 -2.562,-2.36Z"
                            style={{
                                fill: ColorThemes[this.props.color].man4,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path
                            d="M71.782,52.966c-2.26,-0.311 -4.182,-3.108 -0.704,-4.369c3.479,-1.261 5.051,2.867 3.858,4.188"
                            style={{
                                fill: ColorThemes[this.props.color].man4,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path
                            d="M70.796,65.888l-3.067,7.531l3.636,3.538c-0,-0 -3.251,1.27 -2.672,2.667"
                            style={{
                                fill: ColorThemes[this.props.color].man4,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                    </g>
                    <g id="person5">
                        <path
                            d="M80.345,75.712l4.307,-4.655c-0,0 -5.038,-2.853 1.188,-12.647c-0.676,-0.21 -5.508,-0.483 -5.508,-0.483c-0,0 0.013,-4.695 -0.026,-5.413c-1.459,-0.815 -1.424,-2.209 1.699,-2.206c0.376,1.494 0.543,4.604 0.543,4.604l4.326,0.064l6.675,-0.491l4.288,-1.541l3.112,2.272l0.469,4.471l-3.829,-2.698l-2.24,1.07c-0,0 -1.456,4.678 -2.341,5.748c0.737,-0.054 3.779,-0.645 3.779,-0.645l1.047,5.12l4.019,-0.229l-0.24,2.295l-6.162,1.925l-1.026,-6.012c0,-0 -4.866,1.971 -6.846,2.148c0.142,0.868 1.368,3.131 1.368,3.131c0,-0 -3.493,3.114 -3.903,3.458c1.45,1.295 3.733,8.21 -4.699,0.714Z"
                            style={{
                                fill: ColorThemes[this.props.color].man5,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                        <path
                            d="M89.842,57.158c-3.294,-0.874 -4.042,-4.516 -0.729,-5.52c3.313,-1.003 4.804,2.71 4.283,4.682"
                            style={{
                                fill: ColorThemes[this.props.color].man5,
                                stroke: "#000",
                                strokeWidth: "1.39px",
                            }}
                        />
                    </g>
                </g>
            </svg>
        );
    }
}

export default Dance;
